import * as Sentry from '@sentry/react';
import { lazy } from 'react';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { RouterErrorBoundaryFallback500Redirect } from '../components/RouterErrorBoundary/RouterErrorBoundary';

import { RedirectTo404 } from './guards/RedirectTo404';
import { RACRouterProvider } from './providers/RACRouterProvider';

const routes = [
  {
    path: '/400',
    Component: lazy(() => import('../pages/public/shared/ErrorPage/ErrorPage')),
  },
  {
    path: '/403',
    Component: lazy(() => import('../pages/public/shared/ErrorPage/ErrorPage')),
  },
  {
    path: '/404',
    Component: lazy(() => import('../pages/public/shared/ErrorPage/ErrorPage')),
  },
  {
    path: '/500',
    Component: lazy(() => import('../pages/public/shared/ErrorPage/ErrorPage')),
  },
  {
    path: '/:stateId?',
    Component: lazy(() => import('../pages/public/calculator/PublicCalculator')),
  },
];

const sentryCreateBrowserRouter =
  process.env.NODE_ENV === 'production' ? Sentry.wrapCreateBrowserRouter(createBrowserRouter) : createBrowserRouter;

const router = sentryCreateBrowserRouter([
  {
    element: (
      <RACRouterProvider>
        <Outlet />
      </RACRouterProvider>
    ),
    ErrorBoundary: RouterErrorBoundaryFallback500Redirect,
    children: [
      ...routes.map((route) => {
        const { path, Component } = route;
        return {
          path,
          element: <Component />,
        };
      }),
      {
        path: '*',
        element: <RedirectTo404 />,
      },
    ],
  },
]);

export function RouterCalculator() {
  return <RouterProvider router={router} fallbackElement={null} />;
}
